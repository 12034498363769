import React from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"
import SingleStoryCta from 'components/singleStoryCta'
import NextStory from 'components/nextStory'
import BookingCta from 'components/bookingCta'

import '../sass/successStoriesSingle.sass'

export default ({ data: {
    siteFields: {
        story,
    }
} }) => {

    // Booking CTA
    const bookingTitle = "Wondering how your organisation can train with FLAIM? Book a call now to discuss."
    const bookingLink = {
        title: 'BOOK A CALL',
        url: 'https://meetings.hubspot.com/dani-dambrosio'
    }

    const {
        title,
        industries,
        stories_fields: {
            company,
            companyBlurb,
            companyWebsite,
            companyLogo,
            stats,
            caseStudyLink,
            videoLink,
            youtubeLink,
            content,
            nextPost
        }
    } = story

    return (
        <>
            <SEO title={title} description={companyBlurb} />
            <div className="successStorySingle">
                <div className='container'>
                    <div className='inner-container'>
                        <h5 dangerouslySetInnerHTML={{ __html: industries.nodes[0] && industries.nodes[0].name.toUpperCase() }} />
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                        <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                    <SingleStoryCta 
                        name={company}
                        blurb={companyBlurb}
                        url={companyWebsite}
                        stat={stats}
                        caseLink={caseStudyLink}
                        video={videoLink}
                        youtube={youtubeLink}
                        logo={companyLogo}
                    />
                </div>
                {nextPost &&
                    <NextStory content={nextPost}/>
                }
            </div>
            <section className='BookingCta'>
                <BookingCta title={bookingTitle} link={bookingLink} />
            </section>
        </>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            story(id: $databaseId, idType: DATABASE_ID) {
                featuredImage {
                    mediaItemUrl
                }
                title
                link
                stories_fields {
                    company
                    companyBlurb
                    companyWebsite
                    companyLogo {
                        mediaItemUrl
                    }
                    stats {
                        subTitle
                        title
                    }
                    videoLink {
                        mediaItemUrl
                    }
                    youtubeLink
                    companyLogo {
                        mediaItemUrl
                    }
                    caseStudyLink {
                        title
                        url
                    }
                    content
                    nextPost {
                        ... on WPGraphQL_Story {
                            id
                            slug
                            link
                            title
                            industries {
                                nodes {
                                    name
                                }
                            }
                            featuredImage {
                                mediaItemUrl
                            }
                            stories_fields {
                                companyLogo {
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                }
                industries {
                    nodes {
                        name
                        slug
                    }
                }
            }
            stories(first: 1000, after: null) {
                nodes {
                    featuredImage {
                      mediaItemUrl
                    }
                    title
                    link
                    stories_fields {
                      company
                      companyBlurb
                      companyWebsite
                      stats {
                        subTitle
                        title
                      }
                      videoLink {
                          mediaItemUrl
                      }
                      companyLogo {
                        mediaItemUrl
                      }
                    }
                    industries {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`