import React, { useContext } from 'react'
import { MainContext } from 'context/context'

export default ({
    name,
    blurb,
    url,
    stat,
    caseLink,
    video,
    youtube,
    logo
}) => {

    const {
        openModal
    } = useContext(MainContext)

    let id = ''
    let type = ''

    if (youtube) {
        id = youtube.split('=')[1]
        type = 'YOUTUBE'
    } else if (video) {
        type = 'VIDEO'
        id = video.mediaItemUrl
    }

    const handleModalClick = () => {
        openModal({
            type: type,
            data: id
        })
    }

    return (
        <div className='single-story-cta'>
            <div className="cta-content">
                {logo &&
                    <img src={logo.mediaItemUrl} alt='cta logo'/>
                }
                <h4 dangerouslySetInnerHTML={{ __html: name ? name : '' }} />
                <div className='copy' dangerouslySetInnerHTML={{ __html: blurb ?  blurb : '' }} />
                {url &&
                    <a className='web-url' href={url} target="_blank">View website</a>
                }
                {stat &&
                    <div className='stat-group'>
                        {stat.map((item, index) => {
                            const { title, subTitle } = item
                            return (
                                <li key={index}>
                                    <h1 dangerouslySetInnerHTML={{ __html: title ? title : '' }} />
                                    <h4 dangerouslySetInnerHTML={{ __html: subTitle ? subTitle : '' }} />
                                </li>
                            )
                        })}
                    </div>
                }
                {caseLink &&
                    <a href={caseLink.url} target='_blank' rel="noopener noreferrer">
                        <h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.129" height="28.344" viewBox="0 0 26.129 28.344">
                                <path id="Path_602" data-name="Path 602" d="M29.9,10.514,28.693,8.7l-8.186,5.462V-2.85H18.322V14.165L10.136,8.7,8.924,10.514l10.49,7,10.49-7m2.574,14.98V17.876H30.294v5.432H8.535V17.876H6.35v7.617Z" transform="translate(-6.35 2.85)"/>
                            </svg>
                            {caseLink.title.toUpperCase()}
                        </h5>
                    </a>
                }
                {video &&
                    <a
                        onClick={handleModalClick}
                    >
                        <h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.002" height="22.501" viewBox="0 0 18.002 22.501">
                                <path id="Icon_ionic-ios-play" data-name="Icon ionic-ios-play" d="M9,7.334V28.666a.549.549,0,0,0,.823.506l16.91-10.666a.6.6,0,0,0,0-1.005L9.823,6.834A.544.544,0,0,0,9,7.334Z" transform="translate(-9 -6.751)"/>
                            </svg>
                            WATCH VIDEO
                        </h5>
                    </a>
                }
            </div>
        </div>
    )
}

