import React from 'react'
import Link from 'components/link'

export default ({
    content
}) => {

    const {
        industries,
        link,
        title,
        featuredImage,
        stories_fields
    } = content

    return (
        <div className='next-story'>
            <div className="container">
                <h4 className='next-h3'>NEXT STORY</h4>
                <div className='main-content'>
                    <Link to={link}>
                        <div className='image-cont'>
                            {featuredImage &&
                                <img src={featuredImage.mediaItemUrl}/>
                            }
                        </div>
                        <section>
                            {stories_fields.companyLogo &&
                                <img src={stories_fields.companyLogo.mediaItemUrl} />
                            }
                            <h4 dangerouslySetInnerHTML={{ __html: industries && industries.nodes[0] ? industries.nodes[0].name : '' }}/>
                            <h2 dangerouslySetInnerHTML={{ __html: title ? title : '' }}/>
                            <button>READ STORY</button>
                        </section>
                    </Link>
                </div>
            </div>
        </div>
    )
}
