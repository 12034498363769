import React from 'react'
import Button from './button'

export default function bookingCta({
    title,
    link
}) {
    return (
        <div className='booking-cta'>
            <div className="container">
                <h2 dangerouslySetInnerHTML={{ __html: title ? title : ''}} />
                {link &&
                    <Button label={link.title} url={link.url} />
                }
            </div>
        </div>
    )
}
